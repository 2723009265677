<template>
    <div class="col-md-12" style="margin-top:20px">
        <div class="participants-grid">
            <div class="title-block">
                <h3>
                    <strong>Signing Rooms</strong>
                </h3>
            </div>
            <div style="max-width:800px">
                <div class="k-card">
                    <div class="k-card-header" style="display:flex;cursor:pointer;"
                         @click="filterButtonClick">
                        <h4>Snapshot Summary</h4>

                        <div class="k-spacer"></div>

                        <span v-if="!showFilter" class="k-icon k-i-arrow-60-down" style="font-size:28px;padding-top:10px"></span>
                        <span v-if="showFilter" class="k-icon k-i-arrow-60-up" style="font-size: 28px; padding-top: 10px "></span>
                    </div>
                    <div v-if="showFilter">
                        <div class="k-card-body">
                            <table>
                                <thead>
                                    <tr>
                                        <td class="header">Package Status</td>
                                        <td class="header" align="right">Count</td>
                                        <td class="header" align="right">Amount</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="status in packageStatusOrder">
                                        <td>
                                            <k-radio-button :name="'packageStatusGroup'"
                                                            :value="status.statusDescription"
                                                            :checked="selectedPackageStatus==status.statusDescription"
                                                            @click="handleStatusFilterChange"
                                                            :label="status.statusDescription.replace('_', ' ')" />
                                        </td>
                                        <td class="text-right">
                                            {{packageStatusCount[status.statusDescription]}}
                                        </td>
                                        <td class="text-right">
                                            {{packageStatusAmount[status.statusDescription]}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="k-card-body">
                            <table>
                                <thead>
                                    <tr>
                                        <td class="header">Notary Status</td>
                                        <td class="header" align="right">Count</td>
                                        <td class="header" align="right">Amount</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="status in notaryDataSource">
                                        <td>
                                            <k-radio-button :name="'notaryGroup'"
                                                            :value="status.name"
                                                            :checked="selectedNotaryStatus==status.name"
                                                            @click="handleNotaryFilterChange"
                                                            :label="status.name" />
                                        </td>
                                        <td class="text-right">
                                            {{notaryStatusCount[status.name]}}
                                        </td>
                                        <td class="text-right">
                                            {{notaryStatusAmount[status.name]}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <br />
            </div>

            <kendo-datasource ref="datasource1"
                              :filter="filter"
                              @filterchange="filterChange"
                              :transport-read="readData"
                              :schema-model-id="'loanPackageID'"
                              :schema-model-fields="schemaModelFields"
                              :schema-data="'data'"
                              :schema-total="'total'"
                              :schema-groups="'groups'"
                              :schema-aggregates="'aggregates'"
                              :schema-errors="'errors'"
                              :transport-parameter-map="parameterMap"
                              :server-filtering="true"
                              :server-paging="true"
                              :page-size='20'>
            </kendo-datasource>
            <kendo-grid ref="kendoGridRef"
                        :data-source-ref="'datasource1'"
                        :pageable="pageable"
                        :editable="false"
                        :sortable="true"
                        :resizable="true"
                        :reorderable="true"
                        :navigatable="true"
                        :persistSelection="true"
                        :filterable="shouldFilter"
                        :groupable="true"
                        :columnMenu="{componentType: 'modern'}"
                        :columnHide="columnVisibilityEvent"
                        :columnShow="columnVisibilityEvent"
                        :pdf-all-pages="false"
                        :pdf-avoid-links="false"
                        :pdf-margin="{ top: '2cm', left: '1cm', right: '1cm', bottom: '1cm' }"
                        :pdf-landscape="true"
                        :pdf-repeat-headers="true"
                        @selectionchange="onSelectionChange"
                        @databound="packageDataBound"
                        @headerselectionchange="onHeaderSelectionChange"
                        @columnReorder="columnReorder">
                <toolbar>

                    <button title="Export to Excel" class="k-button k-primary" @click="exportSelectedExcel">
                        Export Selected to Excel
                    </button>&nbsp;
                    <button class="k-button k-primary" @click="exportSelectedPDF">
                        Export Selected to PDF
                    </button>
                    <button title="Export to Excel" class="k-button k-primary" @click="exportExcel">
                        Export All to Excel
                    </button>&nbsp;
                    <button class="k-button k-primary" @click="exportPDF">
                        Export All to PDF
                    </button>
                    <div class="k-spacer"></div>
                    <button class="k-button k-primary" title="Add" style="background-color:green" @click="addSigningRoom" v-show="show">
                        Add
                    </button>&nbsp;
                </toolbar>
                <kendo-grid-column :selectable="true" width="70px" :menu="false"></kendo-grid-column>
                <kendo-grid-column :field="'loanPackageID'" :title="'ID#'" width="80px" :filterable="false" :menu="false" hidden="true"></kendo-grid-column>
                <kendo-grid-column :field="'packageName'" :title="'Package Name'" width="200px" :template="cellTmp" :filterable="true" :menu="false"></kendo-grid-column>
                <kendo-grid-column :field="'loanNumber'" :title="'Loan#'" width="200px" :template="loanNumberTemplate" :filterable-extra="false"></kendo-grid-column>
                <kendo-grid-column :field="'packageDescription'" :title="'Package Description'" width="250px" :filterable="true"></kendo-grid-column>
                <kendo-grid-column :field="'borrowerName'" :title="'Signer'" width="170px" :filterable-search="true"></kendo-grid-column>
                <kendo-grid-column :field="'signingDate'" :title="'Signing Date'" width="200px" :format="dateFormat" :filterable-ui="'datepicker'"></kendo-grid-column>
                <kendo-grid-column :field="'signingRoom'" :title="'Signing Room'" width="200px" :template="signingRoomTemplate"></kendo-grid-column>
             
                <kendo-grid-column :field="'statusDescription'" :title="'Status'" width="140px" :filterable="true" :filterable-multi="true" :filterable-data-source="statusDataSource" :template="statusTemplate"></kendo-grid-column>
                <kendo-grid-column :field="'lenderName'" :title="'Lender'" width="200px" :filterable="true"></kendo-grid-column>
                <kendo-grid-column :field="'settlementAgentName'" :title="'Settlement Agent'" width="200px" :filterable="true"></kendo-grid-column>
                <kendo-grid-column :field="'notaryStatusDescription'" :title="'Notary Status'" width="180px" :filterable="true" :filterable-multi="true" :filterable-data-source="notaryDataSource" :template="notaryStatusTemplate"></kendo-grid-column>
                <kendo-grid-column :field="'signingDescription'" :title="'Signing Type'" width="150px" :filterable="true" :filterable-multi="true" :filterable-data-source="signingTypeDataSource"></kendo-grid-column>
                <kendo-grid-column :field="'propertyAddress'" :title="'Property Address'" width="250px" :filterable="false"></kendo-grid-column>
                <kendo-grid-column :field="'createdDate'" :title="'Created Date'" width="200px" :format="dateFormat" :filterable-ui="'datepicker'"></kendo-grid-column>
                <kendo-grid-column :field="'notaryAssigned'" :title="'Notary Assigned'" width="170px"></kendo-grid-column>
                <kendo-grid-column :field="'status'" :title="'Video Access'" :template="showRequestLink" width="170px"></kendo-grid-column>
                <kendo-grid-column :field="'appointmentTime'" :title="'Appointment Time'" :format="dateFormat" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'documentsUploadedDate'" :title="'Documents Uploaded'" :format="dateFormat" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'loanScheduledDate'" :title="'Loan Scheduled'" :format="dateFormat" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'notaryAcceptedDate'" :title="'Notary Accepted'" :format="dateFormat" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'notaryConfirmedWithBorrowerDate'" :title="'Notary Confirmed with Borrower'" :format="dateFormat" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'signingCancelledDate'" :title="'Signing Cancelled'" :format="dateFormat" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'signingRescheduledDate'" :title="'Signing Resecheduled'" :format="dateFormat" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'notaryPrintsDocumentsDate'" :title="'Notary Printed Documents'" :format="dateFormat" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'signingUnableToBeCompletedDate'" :title="'Signing Unable to be Completed'" :format="dateFormat" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'documentsReceivedDate'" :title="'Documents Receieved'" :format="dateFormat" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'signingCompleteDate'" :title="'Signing Complete'" :format="dateFormat" width="200px"></kendo-grid-column>
                <kendo-grid-column :field="'fedExStatus'" :title="'FedEx Status'" width="200px" :filterable-extra="false"></kendo-grid-column>
                <kendo-grid-column :field="'fedExTracking'" :title="'FedEx Tracking'" width="200px" :filterable-extra="false"></kendo-grid-column>
            </kendo-grid>

            <pdfexport ref="gridPdfExport" v-show="false">
                <native-grid :columns="columns" />
            </pdfexport>
        </div>
    </div>
</template>

<style scoped>
    .right-aligned {
        text-align: right;
        float: right;
    }

    .counter-bubble-container {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    td.header {
        padding-bottom: 20px;
        font-weight: bold;
    }

    td {
        padding-bottom: 8px;
    }

    .k-grid-header .k-header > .k-link {
        text-overflow: clip;
        overflow: visible;
        word-wrap: break-word;
        
    }

    .k-card-body {
        float: left;
    }

    .status {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        color: white;
        font-weight: 900;
    }

        .status /deep/ span {
            display: block;
            padding: 10px;
            text-align: center;
            width: 100%;
        }

        .status.in_progress /deep/ span {
            background: #f3a638;
        }

        .status.closed /deep/ span, .status.completed /deep/ span {
            background: #54b7d3;
        }

        .status.archived /deep/ span {
            background: #E3D4D4;
        }

        .status.cancelled /deep/ span,
        .status.opted_out /deep/ span,
        .status.wet_sign /deep/ span,
        .status.printed /deep/ span {
            background: slategray;
        }

        .status.kba_failed /deep/ span {
            background: #e3503e;
        }

    .notary_status {
        display: block;
        width: 90%;
        height: 70%;
        position: relative;
        color: white;
        font-weight: 900;
    }

        .notary_status /deep/ span {
            display: block;
            padding: 10px;
            text-align: center;
            width: 100%;
            background: #0782D9;
        }

        .notary_status.loan_scheduled /deep/ span {
            background: slategray;
        }

        .notary_status.signing_to_be_rescheduled /deep/ span {
            background: #f3a638;
        }

        .notary_status.signing_unable_to_be_completed /deep/ span,
        .notary_status.signing_cancelled /deep/ span,
        .notary_status.missing_documents /deep/ span {
            background: #bb3021;
        }

        .notary_status.completed /deep/ span {
            background: #279327;
        }

    .loanlink {
        color: #4d97ac;
        font-weight: 600;
    }

    .mb-3.search {
        float: right;
        background: #e8e8e8;
        margin: -65px 0 0 0;
        padding: 8px;
        width: 250px;
    }

        .mb-3.search /deep/ input {
            padding: 0 30px 0 0;
            border: none;
            background: none;
            box-shadow: none;
        }

            .mb-3.search /deep/ input:focus {
                border: none;
                background: none;
                box-shadow: none;
                transition: none;
            }

        .mb-3.search .material-icons {
            float: right;
            margin: -28px 0 0 35px;
            border: none;
            background: none;
        }
</style>
<script>
    import { GridToolbar, Grid as NativeGrid } from '@progress/kendo-vue-grid';
    import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { KendoDataSource } from '@progress/kendo-datasource-vue-wrapper';
    import { GridPdfExport } from '@progress/kendo-vue-pdf';
    import { saveExcel } from '@progress/kendo-vue-excel-export';
    import ApiService from "../../core/services/api-service";
    import GridDataHelper from "../../core/services/grid-data-helper";
    import PermissionHelper from "../../core/permission-helper";
    import { process } from '@progress/kendo-data-query';
    import { Input, RadioButton } from '@progress/kendo-vue-inputs';
    import { Button } from '@progress/kendo-vue-buttons';
    import dashboardCanViewLoanCellTemplate from "../templates/dashboardCanViewLoanCellTemplate";
    import dashboardCannotViewLoanCellTemplate from "../templates/dashboardCannotViewLoanCellTemplate";
    import videoAccessTemplate from "../templates/videoAccessTemplate";
    import { ZapfDingbatsEncoding } from '../../../public/pdf.worker';
    import { isProxy, toRaw } from 'vue';

    const SIGN_TYPE_MAP = [{ id: 1, signingDescription: 'eSign' }, { id: 2, signingDescription: 'RON' }, { id: 3, signingDescription: 'PRON' }, { id: 4, signingDescription: 'IPEN' }, { id: 5, signingDescription: 'HYBRID_WET_DOCS_AND_RECORDABLES' }, { id: 6, signingDescription: 'WET_SIGN' }, { id: 7, signingDescription: 'HYBRID_WET_RECORDABLES' }, { id: 8, signingDescription: 'HYBRID_WET' }, { id: 9, signingDescription: 'EMAIL' }, { id: 10, signingDescription: 'HYBRID' }];
    const PACKAGE_STATUS_MAP = [{ id: 1, statusDescription: 'In_Progress' }, { id: 2, statusDescription: 'Completed' }, { id: 3, statusDescription: 'Cancelled' }, { id: 4, statusDescription: 'Archived' }, { id: 5, statusDescription: 'Opted_Out' }, { id: 6, statusDescription: 'Wet_Sign' }, { id: 7, statusDescription: 'Closed' }, { id: 8, statusDescription: 'Printed' }];
    const NOTARY_STATUS_MAP = [{ id: 1, name: "Consumer Invited" }, { id: 2, name: "Assigned To Notary" }, { id: 3, name: "Closing Scheduled" }, { id: 4, name: "On-Hold" }, { id: 5, name: "Completed" }]

    const PERMISSION_PREFIX = "Dashboard_";

    export default {
        name: 'AdminDashboard',
        components: {
            'kendo-grid': Grid,
            'kendo-grid-column': GridColumn,
            'kendo-datasource': KendoDataSource,
            'pdfexport': GridPdfExport,
            'toolbar': GridToolbar,
            'dropdownlist': DropDownList,
            'k-button': Button,
            'k-input': Input,
            'dashboardCanViewLoanCellTemplate': dashboardCanViewLoanCellTemplate,
            'dashboardCannotViewLoanCellTemplate': dashboardCannotViewLoanCellTemplate,
            'native-grid': NativeGrid,
            'k-radio-button': RadioButton
        },
        created() {
            this.permissions = PermissionHelper.GetPermissions(PERMISSION_PREFIX);
        },
        mounted() {
            var self = this;

            ApiService.GenerateGetRequest("Admin/IsNotary").then(
                response => {
                    this.userRole = response.content.participantType;
                    if (response.content.isNotary == true) {
                        this.$router.push("/admin/orders");
                    }
                }
            );

            ApiService.GenerateGetRequest("Package/enableSigningRoom").then(
                response => {
                    this.show = response.content;
                }
            );

            var loginInfo = this.$store.state.Admin.loginInformation;
            console.log(loginInfo);

            ApiService.GenerateNonLoadingPostRequest("Admin/GetDashboardConfig").then(
                response => {
                    if (response.isSuccess) {
                        var grid = this.$refs.kendoGridRef.kendoWidget();

                        console.log('grid', grid);

                        var colOrder = JSON.parse(response.content);
                        var gridColumns = grid.columns;

                        for (var i = 0; i < colOrder.length; i++) {
                            var col = colOrder[i]
                            var index = gridColumns.findIndex(e => e.field == col.field);
                            grid.reorderColumn(i, gridColumns[index]);
                            if (col.hidden == 'true') {
                                grid.hideColumn(i);
                            }
                        }
                    }
                }
            );
        },
        data: function () {
            return {
                search: null,
                statusDataSource: PACKAGE_STATUS_MAP,
                signingTypeDataSource: SIGN_TYPE_MAP,
                notaryDataSource: NOTARY_STATUS_MAP,
                schemaModelFields: {
                    loanPackageID: { editable: false },
                    packageName: { type: 'string', editable: false },
                    packageDescription: { type: 'string', editable: false },
                    loanNumber: { type: 'string', editable: false },
                    borrowerName: { type: 'string', editable: false },
                    propertyAddress: { type: 'string', editable: false },
                    signingDate: { type: 'date', editable: false },
                    createdDate: { type: 'date', editable: false },
                    statusDescription: { type: 'string', editable: false },
                    signingType: { type: 'string', editable: false },
                    signingDescription: { type: 'string', editable: false },
                    packageDescription: { type: 'string', editable: false },
                    notaryAssigned: { type: 'string', editable: true },
                    status: { type: 'string', editable: false },
                    appointmentTime: { type: 'date', editable: false },
                    documentsUploadedDate: { type: 'date', editable: false },
                    loanScheduledDate: { type: 'date', editable: false },
                    notaryAcceptedDate: { type: 'date', editable: false },
                    notaryConfirmedWithBorrowerDate: { type: 'date', editable: false },
                    signingCancelledDate: { type: 'date', editable: false },
                    signingRescheduledDate: { type: 'date', editable: false },
                    notaryPrintsDocumentsDate: { type: 'date', editable: false },
                    signingUnableToBeCompletedDate: { type: 'date', editable: false },
                    documentsReceivedDate: { type: 'date', editable: false },
                    signingCompleteDate: { type: 'date', editable: false },
                    selected: { editable: false }
                },
                selected: [],
                shouldFilter: true,
                filter: {},
                columns: [
                    { field: "loanPackageID", title: 'Package ID', hidden: true },
                    { field: "packageName", title: 'Package Name' },
                    { field: "packageDescription", title: 'Description' },
                    { field: "loanNumber", title: 'Loan Number' },
                    { field: "borrowerName", title: 'Signer' },
                    { field: "propertyAddress", title: 'Property Address' },
                    { field: "signingDate", format: "{0:d}", title: 'Signing Date', width: 150, cellOptions: { format: 'mm/dd/yyyy hh:mm:ss', textAlign: 'left' } },
                    { field: "createdDate", format: "{0:d}", title: 'Created Date', width: 150, cellOptions: { format: 'mm/dd/yyyy hh:mm:ss', textAlign: 'left' } },
                    { field: "statusDescription", title: 'Status' },
                    { field: "notaryStatusDescription", title: 'Notary Status' },
                    { field: "signingDescription", title: 'Signing Type' },
                    { field: "notaryAssigned", title: 'Notary Assigned' },
                    { field: "status", title: 'Video Access' },
                    { field: "appointmentTime", title: 'Appointment Time', width: 150, cellOptions: { format: 'mm/dd/yyyy hh:mm:ss', textAlign: 'left' } },
                    { field: "documentsUploadedDate", title: 'Documents Uploaded', width: 150, cellOptions: { format: 'mm/dd/yyyy hh:mm:ss', textAlign: 'left' } },
                    { field: "loanScheduledDate", title: 'Loan Scheduled', width: 150, cellOptions: { format: 'mm/dd/yyyy hh:mm:ss', textAlign: 'left' } },
                    { field: "notaryAcceptedDate", title: 'Notary Accepted', width: 150, cellOptions: { format: 'mm/dd/yyyy hh:mm:ss', textAlign: 'left' } },
                    { field: "notaryConfirmedWithBorrowerDate", title: 'Notary Confirmed with Borrower', width: 215, cellOptions: { format: 'mm/dd/yyyy hh:mm:ss', textAlign: 'left' } },
                    { field: "signingCancelledDate", title: 'Signing Cancelled', width: 150, cellOptions: { format: 'mm/dd/yyyy hh:mm:ss', textAlign: 'left' } },
                    { field: "signingRescheduledDate", title: 'Signing Resecheduled', width: 150, cellOptions: { format: 'mm/dd/yyyy hh:mm:ss', textAlign: 'left' } },
                    { field: "notaryPrintsDocumentsDate", title: 'Notary Printed Documents', width: 175, cellOptions: { format: 'mm/dd/yyyy hh:mm:ss', textAlign: 'left' } },
                    { field: "signingUnableToBeCompletedDate", title: 'Signing Unable to be Completed', width: 210, cellOptions: { format: 'mm/dd/yyyy hh:mm:ss', textAlign: 'left' } },
                    { field: "documentsReceivedDate", title: 'Documents Receieved', width: 150, cellOptions: { format: 'mm/dd/yyyy hh:mm:ss', textAlign: 'left' } },
                    { field: "signingCompleteDate", title: 'Signing Complete', width: 150, cellOptions: { format: 'mm/dd/yyyy hh:mm:ss', textAlign: 'left' } },
                    { field: "fedExStatus", title: 'FedEx Status', width: 150, cellOptions: { format: 'mm/dd/yyyy hh:mm:ss', textAlign: 'left' } },
                    { field: "fedExTracking", title: 'FedEx Tracking', width: 150, cellOptions: { format: 'mm/dd/yyyy hh:mm:ss', textAlign: 'left' } },
                ],
                pageable: {
                    buttonCount: 10,
                    info: true,
                    type: 'numeric',
                    pageSizes: [20, 50, 100, 200, 500, "all"],
                    previousNext: true,
                },
                permissions: {},
                dateFormat: "{0:MM/dd/yyyy hh:mm tt}",
                selectedPackageStatus: null,
                packageStatusCount: {},
                packageStatusAmount: {},
                selectedNotaryStatus: null,
                packageStatusOrder: [{ id: 1, statusDescription: 'In_Progress' }, { id: 8, statusDescription: 'Printed' }, { id: 3, statusDescription: 'Cancelled' }, { id: 5, statusDescription: 'Opted_Out' }, { id: 7, statusDescription: 'Closed' }, { id: 2, statusDescription: 'Completed' }],
                notaryStatusCount: {},
                notaryStatusAmount: {},
                showFilter: true,
                show: false,
                configLock: false
            }
        },
        computed: {
            areAllSelected() {
                //return this.products.findIndex(item => item.selected === false) === -1;
            },
        },
        methods: {
            cellTmp: function (e) {
                //console.log(e);
                if (this.permissions["CanViewLoan"])
                    return {
                        template: dashboardCanViewLoanCellTemplate,
                        templateArgs: e
                    }
                else
                    return {
                        template: dashboardCannotViewLoanCellTemplate,
                        templateArgs: e
                    }
            },
            signingRoomTemplate: function (e) {
                let html = `<a href="#" data-id="${e.signerID},${e.notarizeID}" class="link signing-room">View</a>`;
                return html;
            },
            handleStatusFilterChange(e) {
                if (e.target.className !== 'k-radio k-radio-md') {
                    return;
                }

                var grid = this.$refs.kendoGridRef.kendoWidget();
                var filter = {};

                if (grid.dataSource.filter() === null || grid.dataSource.filter() === undefined ||
                    Object.keys(grid.dataSource.filter()).length === 0) {
                    filter = {
                        logic: "and",
                        filters: []
                    }
                } else {
                    filter = grid.dataSource.filter();
                }

                for (var i = 0; i < filter.filters.length; i++) {
                    var f = filter.filters[i];
                    if (f.field === 'StatusDescription' || f.field === 'PackageStatus') {
                        filter.filters.splice(i, 1);
                        i--;
                    }
                    filter.logic = 'and';
                }

                if (this.selectedPackageStatus === e.target.value) {
                    this.selectedPackageStatus = null;
                    e.checked = false;

                    if (filter.filters.length === 0) {
                        filter = new Object();
                    }
                } else {
                    this.selectedPackageStatus = e.target.value;
                    filter.filters.push({ field: "StatusDescription", operator: "eq", value: e.target.value });
                    if (e.target.value == "Printed") {
                        filter.filters.push({ field: "StatusDescription", operator: "eq", value: "Wet_Sign" });
                        filter.logic = 'or';
                    }
                }

                grid.dataSource.filter(filter);
            },
            handleNotaryFilterChange(e) {
                if (e.target.className !== 'k-radio k-radio-md') {
                    return;
                }

                var grid = this.$refs.kendoGridRef.kendoWidget();
                var filter = {};

                if (grid.dataSource.filter() === null || grid.dataSource.filter() === undefined ||
                    Object.keys(grid.dataSource.filter()).length === 0) {
                    filter = {
                        logic: "and",
                        filters: []
                    }
                } else {
                    filter = grid.dataSource.filter();
                }

                for (var i = 0; i < filter.filters.length; i++) {
                    var f = filter.filters[i];
                    if (f.field === 'NotaryStatusDescription') {
                        filter.filters.splice(i, 1);
                        i--;
                    }
                }

                if (this.selectedNotaryStatus === e.target.value) {
                    this.selectedNotaryStatus = null;
                    e.checked = false;

                    if (filter.filters.length === 0) {
                        filter = {};
                    }
                } else {
                    this.selectedNotaryStatus = e.target.value;
                    filter.filters.push({ field: "NotaryStatusDescription", operator: "eq", value: e.target.value });
                }

                grid.dataSource.filter(filter);
            },
            loanNumberTemplate(e) {
                var html = `<span style="word-break: break-all">${e.loanNumber}</span>`;
                return html;
            },
            statusTemplate(e) {
                var html = `<div class="status ` + e.statusDescription.toLowerCase() + `" ` + this.$options.__scopeId + `><span>` + GridDataHelper.normalizeStatus(e.statusDescription) + `</span></div>`;
                return html;
            },
            notaryStatusTemplate(e) {
                if (e.notaryStatusDescription === null) {
                    return "";
                }

                var html = `<div class="notary_status ` + e.notaryStatusDescription?.toLowerCase().replace(' ', '_') + `" ` + this.$options.__scopeId + `><span>` + GridDataHelper.normalizeStatus(e.notaryStatusDescription) + `</span></div>`;
                return html;
            },
            formatSigningDate(e) {
                return moment.parseZone(e.signingDate).format("MM/DD/YYYY hh:mm A");
            },
            formatCreatedDate(e) {
                return moment.parseZone(e.createdDate).format("MM/DD/YYYY hh:mm A");
            },
            showRequestLink: function (e) {
                if (!e.hasRecordingAccess) {
                    return {
                        template: videoAccessTemplate,
                        templateArgs: e
                    }
                } else {
                    return `${e.status}`;
                }
            },
            parameterMap: function (options, operation) {
                if (operation !== 'read' && options.models) {
                    return { models: kendo.stringify(options.models) }
                }
            },
            filterButtonClick: function (e) {
                this.showFilter = !this.showFilter;
            },
            readData: function (e) {
                var user = this.$store.state.Admin.loginInformation;

                if (e != null && e.data != null && e.data.filter != null) {
                    if (isProxy(e.data)) {
                        e.data = toRaw(e.data);
                    }
                    if (isProxy(e.data.filter)) {
                        e.data.filter = toRaw(e.data.filter);
                    }

                    for (var i = 0; i < e.data.filter.filters.length; i++) {
                        let fld = e.data.filter.filters[i].field;
                        e.data.filter.filters[i].field = fld.charAt(0).toUpperCase() + fld.slice(1);

                        if (e.data.filter.filters[i].field == 'SigningDescription') {
                            var replaceItem = SIGN_TYPE_MAP.find((x) => x.signingDescription == e.data.filter.filters[i].value);
                            if (replaceItem !== undefined) {
                                e.data.filter.filters[i].field = 'SigningType';
                                e.data.filter.filters[i].value = replaceItem.id;
                            }
                        }

                        if (e.data.filter.filters[i].field == 'StatusDescription') {
                            var replaceItem = PACKAGE_STATUS_MAP.find((x) => x.statusDescription == e.data.filter.filters[i].value);
                            if (replaceItem !== undefined) {
                                e.data.filter.filters[i].field = 'PackageStatus';
                                e.data.filter.filters[i].value = replaceItem.id;
                            }
                        }
                    }
                }

                let self = this;

                return ApiService.GeneratePostRequest(`Package/user`, e.data).then(
                    response => {
                        console.log("client packages", response["content"]);
                        var content = response["content"];

                        e.success(content);

                        var grid = this.$refs.kendoGridRef.kendoWidget();
                        console.log("Grid", grid);

                        ApiService.GeneratePostRequest(`Package/PackageStatus`, e.data).then(
                            response => {
                                var data = response["content"];

                                var statusCount = {};
                                var statusAmount = {};

                                var statusCount = {};
                                var statusAmount = {};

                                for (var i = 0; i < this.statusDataSource.length; i++) {
                                    var name = this.statusDataSource[i].statusDescription;
                                    if (data[name] === undefined) {

                                        statusCount[name] = 0;
                                        statusAmount[name] = "-";
                                    } else {
                                        statusCount[name] = data[name].count.toLocaleString();
                                        statusAmount[name] = data[name].amount;
                                    }
                                }

                                this.packageStatusCount = statusCount;
                                this.packageStatusAmount = statusAmount;
                            });

                        ApiService.GeneratePostRequest(`Package/NotaryStatus`, e.data).then(
                            response => {
                                var data = response["content"];

                                var statusCount = {};
                                var statusAmount = {};

                                var statusCount = {};
                                var statusAmount = {};

                                for (var i = 0; i < this.notaryDataSource.length; i++) {
                                    var name = this.notaryDataSource[i].name;
                                    if (data[name] === undefined) {

                                        statusCount[name] = 0;
                                        statusAmount[name] = "-";
                                    } else {
                                        statusCount[name] = data[name].count.toLocaleString();
                                        statusAmount[name] = data[name].amount;
                                    }
                                }

                                this.notaryStatusCount = statusCount;
                                this.notaryStatusAmount = statusAmount;
                            });
                    }
                );
            },
            onHeaderSelectionChange(event) {
                let checked = event.event.target.checked;
                //this.products = this.products.map((item) => { return { ...item, selected: checked } });
            },
            onSelectionChange: function (event) {
                event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
            },
            filterChange: function (ev) {
                this.filter = ev.filter;
            },
            cancelEditSave() {
                this.itemInEdit = null;
            },
            saveLoan() {
                this.itemInEdit = null;
            },
            exportSelectedExcel() {
                var self = this;
                var grid = this.$refs.kendoGridRef.kendoWidget();
                var selectedKeys = grid.selectedKeyNames();

                var filter = null;

                if (selectedKeys !== null) {
                    filter = {
                        logic: "or",
                        filters: []
                    }

                    selectedKeys.forEach(function (element) {
                        filter.filters.push({ field: "loanPackageID", operator: "eq", value: element });
                    });
                }

                this.readData({
                    data: {
                        filter: filter
                    },
                    success: function (data) {
                        data = process(data.data, {
                            skip: 0
                        }).data;
                        data = self.formatDates(data);

                        saveExcel({
                            data: data,
                            fileName: "loanpackages",
                            columns: grid.columns
                        });
                    }
                });
            },
            exportSelectedPDF() {
                var self = this;
                var grid = this.$refs.kendoGridRef.kendoWidget();
                var selectedKeys = grid.selectedKeyNames();

                var filter = null;

                if (selectedKeys !== null) {
                    filter = {
                        logic: "or",
                        filters: []
                    }

                    selectedKeys.forEach(function (element) {
                        filter.filters.push({ field: "loanPackageID", operator: "eq", value: element });
                    });
                }

                this.readData({
                    data: {
                        filter: filter
                    },
                    success: function (data) {
                        data = process(data.data, {
                            skip: 0
                        }).data;
                        data = self.formatDates(data);

                        (self.$refs.gridPdfExport).save(data);
                    }
                });
            },
            exportExcel() {
                var self = this;

                var grid = this.$refs.kendoGridRef.kendoWidget();
                var filter = null;
                if (grid.dataSource.filter() !== undefined) {
                    filter = toRaw(grid.dataSource.filter());
                }

                this.readData({
                    data: {
                        filter: filter
                    },
                    success: function (data) {
                        data = process(data.data, {
                            skip: 0
                        }).data;

                        data = self.formatDates(data);

                        saveExcel({
                            data: data,
                            fileName: "loanpackages",
                            columns: grid.columns
                        });
                    }
                });
            },
            formatDates(data) {
                var cols = this.$refs.kendoGridRef.kendoWidget().columns;
                var dateFields = [];

                for (var i = 0; i < cols.length; i++) {
                    var col = cols[i];
                    if (col.format) {
                        dateFields.push(col.field);
                    }
                }

                for (var i = 0; i < data.length; i++) {
                    var p = data[i];
                    const fields = Object.keys(p);

                    for (var f = 0; f < fields.length; f++) {
                        var field = fields[f];
                        if (dateFields.indexOf(field) !== -1 && p[field] !== null) {
                            data[i][field] = kendo.toString(new Date(p[field]), "MM/dd/yyyy hh:mm tt");
                        }
                    }
                }

                return data;
            },
            addSigningRoom() {
                this.$router.push("/admin/add-signing-room");
            },
            searchGrid: function () {
                console.log(this.search);
            },
            exportPDF() {
                var self = this;
                var grid = this.$refs.kendoGridRef.kendoWidget();
                var filter = null;
                if (grid.dataSource.filter() !== undefined) {
                    filter = JSON.parse(JSON.stringify(grid.dataSource.filter()));
                }
                this.readData({
                    data: {
                        filter: filter
                    },
                    success: function (data) {
                        console.log("exportPDF All", data);
                        data = process(data.data, {
                            skip: 0
                        }).data;
                        data = self.formatDates(data);

                        (self.$refs.gridPdfExport).save(data);
                    }
                });
            },
            packageDataBound: function (e) {
                console.log('e', e);
                var self = this;
                $(".package-journal.link").on("click", function (e) {
                    e.preventDefault();
                    console.log("pckg e", e.data);
                    self.$router.push('/admin/notary/' + $(this).attr("data-id"));
                });
                $(".k-checkbox").on("click", function (e) {
                    console.log('this', this);
                });

                $(".signing-room.link").on("click", function (e) {
                    e.preventDefault();
                    var data = $(this).attr("data-id");
                    console.log("signing room data id: ", data);
                    self.$store.commit("Auth/updateSigningRoomData",
                        {
                            userId: data.substr(0, data.indexOf(',')),
                            contextId: 0,
                            notarizeId: data.substring(data.indexOf(',') + 1),
                            hist: "AdminDashboard"
                        });
                    self.$router.push('/admin/signing');
                });
            },
            columnReorder: function (e) {
                var self = this;
                var grid = e.sender;
                var col = e.column;

                if (e.oldIndex === 0) {
                    setTimeout(function (e) {
                        grid.reorderColumn(0, col);
                    }, 0);
                } else if (e.newIndex === 0) {
                    var oldIndex = e.oldIndex;
                    setTimeout(function (e) {
                        grid.reorderColumn(oldIndex, col);
                    }, 0);
                }

                setTimeout(function () {
                    self.setDashboardConfig();
                }, 1);
            },
            setDashboardConfig: function () {
                var self = this;
                if (this.$refs === null || this.$refs.kendoGridRef === null || this.$refs.kendoGridRef.kendoWidget() == null
                    || this.configLock) {
                    return;
                }

                this.configLock = true;

                var grid = this.$refs.kendoGridRef.kendoWidget();

                var colOrder = [];

                for (var i = 0; i < grid.columns.length; i++) {
                    var col = grid.columns[i];

                    colOrder[i] = { field: col.field, hidden: col.hidden || col.hidden == 'true' ? 'true' : 'false' };
                }

                ApiService.GenerateNonLoadingPostRequest("Admin/SetDashboardConfig", colOrder).then(function () {
                    self.configLock = false;
                });
            },
            columnVisibilityEvent: function (e) {
                var self = this;
                setTimeout(function () {
                    self.setDashboardConfig();
                }, 1);
            }
        }
    }
</script>