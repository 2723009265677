<template>
    <!-- Stack the columns on mobile by making one full-width and the other half-width -->
    <router-link-bread-crumbs :navItems="navItems" />
    <card class="card">
        <cardHeader>
            <h3>
                <b style="float:left">Loan # : {{loanDetail.loanNumber}}</b>
                <b style="float:right">Packages ({{loanPackages.length}})</b>
            </h3>
        </cardHeader>
        <cardBody>
            <div class="row p-m">
                <div class="row pb-m">
                    <div class="col-md-7 col-sm-12">
                        <h4>{{loanDetail.packageName}}</h4>
                    </div>
                    <div class="col-md-5">
                        <k-button style="background: rgb(255 104 32 / 87%); color: white;" v-if="showForceClose" :look="'solid'" primary="false" @click="handleForceClose" class="pull-right">
                            FORCE CLOSE
                        </k-button>
                        <k-button style="margin-right: 10px" v-if="isNotary" :look="'solid'" primary="false" @click="handleNotaryJournal" class="pull-right">
                            Notary Journal
                        </k-button>
                        <k-button style="margin-right: 10px" v-if="isNotary" :look="'solid'" primary="false" @click="viewSigningRoom" class="pull-right">
                            View Signing Room
                        </k-button>
                    </div>
                </div>
            </div>
            <tabstrip :selected="selected" @select="onSelect">
                <tabstripTab v-for="item in loanPackages" :key="item.loanPackageID" :title="item.packageTypeName" contentClassName="customTabStrip">
                    <div :data-packageId="item.loanPackageID">
                        <div>
                            <div class="row p-m">
                                <div class="row pb-m">
                                    <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12 pb-m">
                                        <div>Signer</div>
                                        <div>
                                            <b>{{ loanDetail.borrowerName }}</b>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-9 col-sm-6 col-xs-12 pb-m">
                                        <div>Property Address</div>
                                        <div>
                                            <b>{{ loanDetail.propertyAddress }}</b>
                                        </div>
                                    </div>
                                    <div class="col-lg-1 col-md-3 col-sm-6 col-xs-12 pb-m">
                                        <div>Package Type</div>
                                        <div>
                                            <b>{{ loanDetail.packageType }}</b>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-9 col-sm-6 col-xs-12 pb-m">
                                        <div>Signing Type</div>
                                        <div>
                                            <b>{{ loanDetail.signingDescription }}</b>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12 pb-m">
                                        <div>Status</div>
                                        <div class="status" :class="loanDetail.statusDescription.toLowerCase()">
                                            <span>{{ normalizeStatus(loanDetail.statusDescription)}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row pb-m">
                                    <div class="col-lg-3 col-xl-2 col-md-4 col-xs-12 pb-m">
                                        <div>Available for Review</div>
                                        <div>
                                            <b>
                                                <datepicker :required="true"
                                                            :style="{ width: '100%'}"
                                                            v-model="this.startDate"
                                                            :default-value="this.startDate"
                                                            :default-Show="false"
                                                            :format="'MM/dd/yyyy'"
                                                            :disabled="!canEdit">
                                                </datepicker>
                                            </b>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-xl-2 col-md-4 col-xs-12 pb-m">
                                        <div>Available for Pre-Sign</div>
                                        <div>
                                            <b>
                                                <datepicker :required="true"
                                                            :style="{ width: '100%' }"
                                                            v-model="this.preSignDate"
                                                            :default-value="this.preSignDate"
                                                            :default-Show="false"
                                                            :format="'MM/dd/yyyy'">
                                                </datepicker>
                                            </b>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-xl-2 col-md-4 col-xs-12 pb-m">
                                        <div>Signing Date</div>
                                        <div>
                                            <b>
                                                <datetimepicker :required="true"
                                                                :style="{ width: '100%' }"
                                                                v-model="this.signDate"
                                                                :default-value="this.signDate"
                                                                :value="this.signDate"
                                                                :default-Show="false"
                                                                :format="'MM/dd/yyyy hh:mm a'"
                                                                @change="changeDateTime"
                                                                name="signingDate"
                                                                :steps="steps">
                                                </datetimepicker>
                                            </b>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-xl-2 col-md-4 col-xs-12 pb-m">
                                        <div>Signing Expiration Date</div>
                                        <div>
                                            <b>
                                                <datetimepicker :required="true"
                                                                :style="{ width: '100%' }"
                                                                v-model="this.signingExpirationDate"
                                                                :default-value="this.signingExpirationDate"
                                                                :value="this.signingExpirationDate"
                                                                :default-Show="false"
                                                                :format="'MM/dd/yyyy hh:mm a'"
                                                                @change="changeDateTime"
                                                                name="signingExpirationDate"
                                                                :steps="steps">
                                                </datetimepicker>
                                            </b>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-xl-3 col-md-4 col-xs-12 pb-m">
                                        <div>Timezone</div>
                                        <div>
                                            <b>
                                                <dropdownlist id="dropdownlist"
                                                              :style="{ width: '100%' }"
                                                              :data-items='timeZones'
                                                              :data-item-key="'id'"
                                                              :text-field="'timeZoneName'"
                                                              :value="selectedTZ"
                                                              @change="changeTimeZone"
                                                              :steps="steps">
                                                </dropdownlist>
                                            </b>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-xl-2 col-md-4 col-xs-12 pb-m">
                                        <div>Access Expiration Date</div>
                                        <div>
                                            <b>
                                                <datepicker :required="true"
                                                            :style="{ width: '100%' }"
                                                            v-model="this.exprDate"
                                                            :default-value="this.exprDate"
                                                            :default-Show="false"
                                                            :format="'MM/dd/yyyy'">
                                                </datepicker>
                                            </b>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="canEdit">
                                    <div class="col-lg-3">
                                        <k-button :look="'solid'" primary="true" @click="savePackageDates">
                                            SAVE
                                        </k-button>
                                    </div>
                                    <div class="col-lg-9">
                                        <k-button :look="'solid'" primary="true" @click="toggleArchiveDialog" style="float:right;">
                                            {{ normalizeStatus(loanDetail.statusDescription) == "Archived" ? "Unarchive" : "Archive" }}
                                        </k-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="solid" />


                        <!--<div style="display: flex; align-items: center; justify-content: space-between;">
                            <h4 style="margin-top:24px">Package Participants</h4>
                            <span v-show="(item.packageNotary!=null && !showNotaryButton) || (showNotaryButton)">
                                <h4 style="display: inline-block;margin-right: 10px;">
                                    {{  item.packageNotary ? 'Assigned Notary: ' + item.packageNotary.notary : 'Add Notary'}}
                                </h4>
                                <k-button :icon="'pencil'" primary="true" @click.prevent="openNotaryAssignmentPopup(item)" v-show="showNotaryButton"></k-button>
                            </span>
                        </div>-->
                        <package-participants v-bind:packageide="item.loanPackageID"
                                              :packageNotary="item.packageNotary"
                                              :pnotarizeId="item.notarizeID"
                                              :key="item.loanPackageID"
                                              :clientRoles="clientRoles"
                                              @readLog="readDocumentLog"/>

                        <h4 style="margin-top:48px">Package Documents</h4>
                        <package-documents v-bind:packageide="item.loanPackageID"
                                           :key="item.loanPackageID"
                                           fetchUrl='Package/documents'
                                           :fetchFilter="filterNotUploaded"
                                           :hiddenCols="docHiddenCols"
                                           @readLog="readDocumentLog"
                                           addText="Add"
                                           :hiddenActions="hiddenActions"
                                           :ref="'packageDocGrid_'+item.loanPackageID" />

                        <h4 style="margin-top:48px">Signed and Uploaded Documents</h4>
                        <package-documents v-bind:packageide="item.loanPackageID"
                                           :key="item.loanPackageID"
                                           :hiddenCols="uploadedDocHiddenCols"
                                           fetchUrl='Package/documents'
                                           :fetchFilter="filterUploaded"
                                           @readLog="readDocumentLog"
                                           :hiddenActions="hiddenActionsUploaded"
                                           addText="Upload"
                                           :ref="'packageDocGrid_'+item.loanPackageID" />

                        <div v-if="loanDetail.hasRecordingAccess==true">
                            <h4 style="margin-top:48px">Recordings</h4>
                            <recordings :notarizeID="item.notarizeID" v-if="item.notarizeID!=0" />
                            <div v-for="r in recordingsList" :key="r.id">
                                {{r.videoUrl}}
                                <k-button class="k-button k-primary" @click="downloadRecording(r.filePath)">
                                    Download
                                </k-button>
                            </div>
                        </div>
                        <h4 class="k-grid-offset">
                            <b> Package Audit Logs </b>
                        </h4>
                        <hr class="solid" />
                        <audit-log :auditLog="auditLog" :pckgId="item.loanPackageID" :ref="'auditlog_'+item.loanPackageID" />
                    </div>
                </tabstripTab>
            </tabstrip>
        </cardBody>
    </card>

    <div ref="notaryModalRef" class="modal" style="display: none;">
        <div>
            <div class="modal-dialog">
                <div class="modal-content" style=" background: white;">
                    <div class="modal-header">
                        <h2 class="modal-title">Select a Notary</h2>
                        <div>
                            <button type="button" class="close" data-dismiss="modal">
                                x
                            </button>
                        </div>
                    </div>

                    <div class="modal-body">
                        <dropdownlist :style="{ width: '100%' }"
                                      :data-items='notaries'
                                      :data-item-key="'userID'"
                                      :text-field="'notary'"
                                      :value="loanNotary != null ? loanNotary : {}"
                                      @change="changeNotary"
                                      :steps="steps">
                        </dropdownlist>


                        <div class="modal-footer">
                            <div align="right" class="pull-right">
                                <button type="button"
                                        class="btn btn-success"
                                        @click.prevent="saveNotary">
                                    Accept
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div ref="outstandingTagsModal" class="modal" style="display: none;">
        <div>
            <div class="modal-dialog">
                <div class="modal-content" style=" background: white;">
                    <div class="modal-header">
                        <h2 class="modal-title" style="text-align:center;">Error Closing Package</h2>
                        <div>
                            <button type="button" class="close" data-dismiss="modal">
                                x
                            </button>
                        </div>
                    </div>

                    <div class="modal-body">
                        <card class="card" v-for="(a, aKey, aIndex) in groupedOutstandingTags">
                            <cardHeader class="text-center">
                                <h3 style="margin-left:.5rem;">{{aKey}}</h3>
                            </cardHeader>
                            <cardBody>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="col-md-9">Document</th>
                                                <th scope="col" class="col-md-3" style="text-align:center;">Mandatory Outstanding Tags</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(b, bKey, bIndex) in a" :key="bIndex">
                                                <td class="col-md-9">{{b.documentDescription}}</td>
                                                <td class="col-md-3" style="text-align:center;">{{b.outstandingTags}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </cardBody>
                        </card>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <k-dialog ref="archivePackageDialog" :title="normalizeStatus(loanDetail.statusDescription) == 'Archived' ? 'Unarchive Package' : 'Archive Package'" 
              v-if="showArchiveDialog" @close="toggleArchiveDialog">
        <div>
            <p v-if="normalizeStatus(loanDetail.statusDescription) != 'Archived'"
               :style="{ margin: '10px', textAlign: 'center' }">Would you like to archive this package?</p>
            <p v-if="normalizeStatus(loanDetail.statusDescription) == 'Archived'"
               :style="{ margin: '10px', textAlign: 'center' }">Would you like to unarchive this package?</p>
            <br />
            <dialog-actions-bar style="border-style: none;">
                <k-button :look="'solid'" primary="true" @click="archivePackage">Yes</k-button>
                <k-button :look="'solid'" @click="toggleArchiveDialog">No</k-button>
            </dialog-actions-bar>
        </div>
    </k-dialog>

</template>
<style>
    div.k-content.k-active.customTabStrip {
        display:initial;
    }
</style>
<style scoped>
    .k-grid-offset {
        margin-top: 80px;
    }

    .card {
        border-radius: 20px;
        margin-bottom: 20px;
    }

    .details,
    .packages {
        padding: 10px 10px 20px 10px;
    }

    .Tabs {
        position: relative;
        background-color: #fff;
        margin: 10px 0;
        padding: 0;
        list-style: none;
        font-family: "Roboto Slab";
    }

        .Tabs label a:hover,
        .Tabs label a {
            text-decoration: none;
            color: black;
            font-weight: bold;
        }

        .Tabs:after {
            content: " ";
            display: table;
            clear: both;
        }

    .Tabs__tab {
        float: left;
        padding: 15px 5px 10px 5px;
        text-align: left;
        font-size: 18px;
    }

    .Tabs__presentation-slider {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 20px;
        height: 4px;
        background-color: #4d97ac;
        transform-origin: 0 0;
        transition: all 0.3s ease;
        margin: 0 0 0 5px;
    }

        .Tabs__presentation-slider button {
            float: right;
            margin: -29px 3px 0 0;
            width: 20px;
            border: none;
            background: none;
        }

    .Tab > a {
        display: block;
        padding: 10px 12px;
        text-decoration: none;
        font-weight: bold;
        color: black;
        transition: color 0.15s;
    }

    .Tab .active > a {
        color: black;
    }

    .Tab .active:hover > a {
        color: black;
    }

    .packages > section {
        position: absolute;
        top: -999em;
        left: -999em;
    }

        .packages > section:first-child {
            position: static;
        }

        .packages > section h2 {
            position: initial;
            display: block;
        }

            .packages > section h2:after {
                content: "";
                display: block;
                height: 3px;
                background: transparent;
                background: -moz-linear-gradient( top, rgba(182, 181, 181, 0.25) 0%, rgba(0, 0, 0, 0) 100% ); /* FF3.6-15 */
                background: -webkit-linear-gradient( top, rgba(182, 181, 181, 0.25) 0%, rgba(0, 0, 0, 0) 100% ); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient( to bottom, rgba(182, 181, 181, 0.25) 0%, rgba(0, 0, 0, 0) 100% ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000', GradientType=0 ); /* IE6-9 */
            }

    /deep/ .k-toolbar .k-add-button {
        float: right;
        margin-top: 5px;
    }

    /deep/ .k-toolbar .k-add-button {
        /*position:absolute;
        right: 0;*/
        margin: -1px 2px 0 8px;
    }

    .status {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        color: white;
        font-weight: 900;
    }

        .status /deep/ span {
            display: block;
            padding: 10px;
            text-align: center;
            width: 100%;
        }

        .status.in_progress /deep/ span {
            background: #f3a638;
        }

        .status.printed /deep/ span {
            background: slategray;
        }

        .status.closed /deep/ span, .status.completed /deep/ span {
            background: #54b7d3;
        }

        .status.archived /deep/ span {
            background: #E3D4D4;
        }

        .status.cancelled /deep/ span,
        .status.opted_out /deep/ span,
        .status.wet_sign /deep/ span {
            background: #e3503e;
        }

        .status.kba_failed /deep/ span {
            background: #e3503e;
        }

    .p-m {
        padding: 16px;
    }

    .pb-m {
        padding-bottom: 16px;
    }
    /* Solid border */
    hr.solid {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        margin-left: -16px;
        margin-left: -16px;
    }

    .customTabStrip {
        width: 100% !important;
    }
</style>
<script>
    import * as Vue from "vue";
    import { useRoute } from "vue-router";
    import ApiService from "@/core/services/api-service";
    import moment from "moment";
    import PackageDocumentsTemplate from "../../components/grids/PackageDocuments";
    import PackageParticipantsTemplate from "../../components/grids/PackageParticipants";
    import RouterLinkBreadCrumbsVue from "../../components/RouterLinkBreadCrumbs.vue";
    import Swal from "sweetalert2/dist/sweetalert2.all.min.js";
    import { DatePicker, DateTimePicker } from "@progress/kendo-vue-dateinputs";
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import AuditLogVue from "../../components/AuditLog.vue";
    import PackageNotaryTemplate from "../../components/grids/PackageNotary";
    import Recordings from "@/components/grids/Recordings";
    import {
        Card,
        CardHeader,
        CardBody,
        CardTitle,
        CardSubtitle,
        CardActions,
    } from "@progress/kendo-vue-layout";
    import { Button } from "@progress/kendo-vue-buttons";
    import GridDataHelper from "../../core/services/grid-data-helper";
    import PermissionHelper from "../../core/permission-helper";
    import { TabStrip, TabStripTab } from '@progress/kendo-vue-layout';
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';

    const PERMISSION_PREFIX = "Loan_";
    const CAN_EDIT = "Detail_CanSaveLoanDetail";


    export default {
        name: "AdminLoanDetail",
        components: {
            "k-button": Button,
            PackageParticipants: PackageParticipantsTemplate,
            PackageNotary: PackageNotaryTemplate,
            PackageDocuments: PackageDocumentsTemplate,
            RouterLinkBreadCrumbs: RouterLinkBreadCrumbsVue,
            AuditLog: AuditLogVue,
            card: Card,
            Recordings: Recordings,
            cardHeader: CardHeader,
            cardBody: CardBody,
            cardTitle: CardTitle,
            cardSubtitle: CardSubtitle,
            cardActions: CardActions,
            'datepicker': DatePicker,
            'datetimepicker': DateTimePicker,
            'dropdownlist': DropDownList,
            'tabstrip': TabStrip,
            'tabstripTab': TabStripTab,
            'k-dialog': Dialog,
            'dialog-actions-bar': DialogActionsBar
        },
        provide() {
            // use function syntax so that we can access `this`
            return {
                permissions: Vue.computed(() => this.permissions)
            }
        },
        data() {
            const route = useRoute();
            const id = route.params.id;
            return {
                loanNumber: id,
                loanDetail: {},
                notaryOfficers: {},
                auditLog: [],
                notaries: [],
                audit: 0,
                loanPackages: [],
                timeZones: [],
                selectedTZ: "",
                exprDate: "",
                signDate: "",
                signingExpirationDate:"",
                startDate: "",
                preSignDate: "",
                statusTemplate: "",
                packageTypeName: "",
                navItems: [],
                steps: {
                    year: 1,
                    month: 1,
                    day: 1,
                    hour: 1,
                    minute: 15,
                    second: 1
                },
                selected: 0,
                permissions: {},
                loanBeingEdited: null,
                loanNotary: null,
                showNotaryButton: true,
                signerData: {},
                isNotary: false,
                showForceClose: false,
                groupedOutstandingTags: {},
                signer: {},
                filterNotUploaded : { 
                    logic: "and",
                    filters : [
                    {
                        "field": "StatusDescription",
                        "operator": "neq",
                        "value": "Uploaded"
                    },
                ]},
                filterUploaded : { 
                    logic: "and",
                    filters : [
                    {
                        "field": "StatusDescription",
                        "operator": "eq",
                        "value": "Uploaded"
                    },
                ]},
                uploadedDocHiddenCols:['wetSigned','availableForPresign','documentType', 'pages'],
                docHiddenCols:['uploaderName'],
                hiddenActionsUploaded:['downloadAllWet', 'edit', 'view', 'downloadAll', 'assignParticipants'],
                hiddenActions: ['downloadAllUploaded', 'acknowledgeUpload'],
                showArchiveDialog: false,

                clientRoles: []
            };
        },
        async created() {
            this.permissions = PermissionHelper.GetPermissions(PERMISSION_PREFIX);
            console.log("Loan Details perm", this.permissions);

            await this.getNotaryOfficers();
            await this.getNotaryIntegration();
            await this.getClientRoles();


        },
        computed: {
            moment: () => moment,
            canEdit: function () { return this.permissions[CAN_EDIT] },
        },
        async mounted() {
            var self = this;
            const route = useRoute();
            //load timezone dropdown
            this.loadTimeZones();
            await self.loadPackageDetails(parseInt(route.params.id));
            ApiService.GenerateGetRequest("Admin/IsNotary").then(
                response => {
                    console.log("Admin/IsNotary", response.content);
                    if (response.content.isNotary == true)
                    {
                        this.isNotary = true;
                        if (this.normalizeStatus(this.loanDetail.statusDescription) != "Completed" && this.normalizeStatus(this.loanDetail.statusDescription) != "Closed") {
                            this.showForceClose = true;
                        }
                            
                    }
                }
            );

        },
        methods: {
            changeNotary(obj) {
                this.loanNotary = obj.value
            },
            saveNotary() {
                let formData = new FormData();

                formData.append("LoanPackageId", this.loanBeingEdited.loanPackageID); // 1044);
                formData.append("AppUserID", this.loanNotary.appUserId);
                formData.append("UserID", this.loanNotary.userID);
                formData.append("Name", this.loanNotary.notary);
                formData.append("AdminUserId", this.$store.state.Admin.loginInformation.userId);
                ApiService.GeneratePostRequest("package/notary/update", formData, { "Content-Type": "multipart/form-data" }).then(
                    response => {
                        console.log("response: ", response)
                        if (!response.isSuccess) { 
                            Swal.fire(
                                "Operation failed",
                                response.message,
                                "error"
                            );

                            $(this.$refs.notaryModalRef).modal("hide");
                        }
                        else { 
                            this.loanBeingEdited.packageNotary = response.content[0];
                            Swal.fire(
                                'Notary updated',
                                '',
                                'success'
                            );

                            $(this.$refs.notaryModalRef).modal("hide");
                        }
                    }
                );
            },
            openNotaryAssignmentPopup(loan) {
                this.loanBeingEdited = loan;
                this.loanNotary = loan.packageNotary;
                $(this.$refs.notaryModalRef).modal("show");
            },
            toggleArchiveDialog() {
                this.showArchiveDialog = !this.showArchiveDialog;
            },
            archivePackage() {
                var id = parseInt(this.$route.params.id);

                return ApiService.GeneratePostRequest("package/archive?packageId=" + id).then(
                    response => {
                        this.toggleArchiveDialog();

                        if (!response.isSuccess) {
                            Swal.fire(
                                "Operation failed",
                                response.message,
                                "error"
                            );
                        }
                        else {
                            if (this.loanDetail.statusDescription == "Archived") {
                                Swal.fire(
                                    'Package unarchived',
                                    '',
                                    'success'
                                );
                            } else {
                                Swal.fire(
                                    'Package archived',
                                    '',
                                    'success'
                                );
                            }

                            ApiService.GeneratePostRequest(
                                "Package/GetLoanPackageById/" + this.loanDetail.loanPackageID
                            ).then((response) => {
                                console.log('loanDetail', this.loanDetail);
                                this.loanDetail = response["content"] || response;
                                console.log(response);

                                if (response.isSuccess) {
                                    this.loanDetail = response.content;
                                }
                            });
                        }
                    }
                );
            },
            async getClientRoles() {
                var self = this;
                await ApiService.GenerateGetRequest(
                    "Role/GetRoles"
                ).then((response) => {
                    self.clientRoles = response.content;
                });
            },
            async getPackageNotary(item) {
                console.log("Getting package notary", item);
                let obj = {
                    LoanID: parseInt(this.$route.params.id),
                    PackageId: parseInt(item.loanPackageID)
                };
                //console.log("item.loanPackageIdresponse", obj)
                let response = await ApiService.GeneratePostRequest(`Package/notary`, obj);
                //console.log("item.loanPackageIdresponse", response)
                obj = null;
                if (response.content.data.length > 0) {
                    obj = response.content.data[0];
                }

                return obj;
            },
            async onSelect(e) {
                //find package by package name
                this.selected = e.selected;
                const packageTypeName = $(".k-tabstrip-items").children().eq(e.selected).first().children().first().text();
                const packageId = this.loanPackages.find(z => z.packageTypeName == packageTypeName).loanPackageID;
                console.log("selected package id", packageId);
                await this.loadPackageDetails(packageId);
            },
            async loadPackageDetails(packageId) {
                console.log("selected package id", packageId);
                var self = this;
                var unauthorized = false;
                await ApiService.GeneratePostRequest(
                    "Package/GetLoanPackageById/" + packageId
                ).then((response) => {
                    self.loanDetail = response["content"] || response;
                    console.log(response);

                    if (response.isSuccess) {
                        self.loanDetail = response.content;
                        this.navItems = [
                            {
                                id: 1,
                                label: "Home",
                                url: "/admin",
                                icon: "home",
                            },
                            {
                                id: 2,
                                label: self.loanDetail.loanNumber,
                                icon: "folder",
                            },
                        ];
                    } else {
                        if (response.message == "Unauthorized") {
                            this.$router.back();
                            unauthorized = true;
                            console.log("Back!");
                            return;
                        }
                        this.$router.push("/admin")
                    }

                });

                if (unauthorized) return;

                this.exprDate = new Date(this.loanDetail.expirationDate);
                this.signDate = new Date(moment.parseZone(this.loanDetail.signingDate).format('YYYY-MM-DDTHH:mm:ss'));
                this.signingExpirationDate = new Date(moment.parseZone(this.loanDetail.eSignExpirationDate).format('YYYY-MM-DDTHH:mm:ss'));
                this.startDate = new Date(this.loanDetail.startDate);
                this.preSignDate = new Date(this.loanDetail.preSignDate);

                await ApiService.GeneratePostRequest(
                    "Package/GetPackageByLoanNumber/" + this.loanDetail.loanNumber
                ).then((response) => {
                    var lp = response;
                    lp.forEach(async (loan) => {
                        loan.packageNotary = await self.getPackageNotary(loan);

                    });
                    self.loanPackages = lp;
                    const packageIdx = self.loanPackages.findIndex(x => x.loanPackageID == packageId);
                    self.selected = packageIdx;
                });
                //get timezone details from package
                await ApiService.GenerateGetRequest("Package/timezone/" + self.loanDetail.timeZone).then(
                    response => {
                        if (response.isSuccess) {
                            self.selectedTZ = response.content;
                        }

                    }
                );
                this.readDocumentLog(packageId);

            },
            async getNotaryOfficers() {
                var self = this;
                await ApiService.GenerateGetRequest(
                    "Package/notaries"
                ).then((response) => {
                    console.log("Package notaries", response);
                    self.notaries = response.content;
                });
            },

            async getNotaryIntegration() {
                var self = this;
                await ApiService.GenerateGetRequest(
                    "Package/notaryIntegration"
                ).then((response) => {

                    if (response.content == true) {
                        self.showNotaryButton = false;
                    }
                });
            },
            documentView: function () {
                return true;
            },
            packagestatus: function () {
                if (this.loanDetail.statusDescription) return this.loanDetail.statusDescription;
            },
            deleteData: function (e) {
                console.log("delete", e);
                let formData = new FormData();
                formData.append("DocumentId", e.data.models[0]["documentID"]); // 1044);
                formData.append("AdminUserId", this.$store.state.Admin.loginInformation.userId);
                return ApiService.GeneratePostRequest(
                    "package/document/delete",
                    formData,
                    { "Content-Type": "multipart/form-data" }
                ).then((response) => {
                    e.success(response);
                    if (response["isSuccess"]) {
                        Swal.fire("Success", "Document deleted sucessfully", "success");
                    } else {
                        Swal.fire(
                            "Operation failed",
                            "Document could not be deleted",
                            "error"
                        );
                    }
                });
            },
            async readDocumentLog(packageId) {
                var self = this;
                console.log("read audit logs", packageId);
                await ApiService.GenerateGetRequest(
                    "Package/GetPackageAuditLog/" + packageId
                ).then((response) => {
                    self.auditLog = response;
                });

                console.log(this.$refs);
                var alRef = this.$refs["auditlog_" + packageId];
                if (Array.isArray(alRef)) {
                    console.log("REF is array");
                    alRef[0].reloadPDF(self.auditLog);
                }
                else
                {
                    console.log("REF is not array");
                    alRef.reloadPDF(self.auditLog);
                }
                console.log("reloaded pdf");

            },
            savePackageDates: function () {
                if (!this.canEdit) return;
                var tempSigningDate = new Date(this.signDate.toISOString().substring(0, 10));
                if (this.startDate > this.exprDate) {
                    Swal.fire("Error: Changes not saved!", "Available for Review Date cannot be after the Expiration Date", "error");
                    return false;
                }
                else
                    this.loanDetail.startDate = moment(this.startDate).format();

                if (this.exprDate < tempSigningDate) {
                    Swal.fire("Error: Changes not saved!", "Expiration Date cannot be before the Signing Date", "error");
                    return false;
                }
                else
                    this.loanDetail.expirationDate = moment(this.exprDate).format();

                // disable date validation for start date

                // if ((tempSigningDate > this.exprDate || tempSigningDate < this.startDate)) {
                //     debugger
                //     if (!moment(tempSigningDate).isSame(moment(new Date(this.startDate.toISOString().substring(0, 10)), 'date'))) {
                //         Swal.fire("Error: Changes not saved!", "Signing Date must be between the Available for Review Date and the Expiration Date", "error");
                //         return false;
                //     }
                // }else{
                //     this.loanDetail.signingDate = moment(this.signDate).format();
                // }
                if(this.signingExpirationDate < this.signDate){
                    Swal.fire("Error: Changes not saved!", "Signing expiration date cannot be before signing date", "error");
                    return false;
                }

                this.loanDetail.signingDate = moment(this.signDate).format();
                this.loanDetail.eSignExpirationDate = moment(this.signingExpirationDate).format();

                // disable date validation for presign

                //if (this.preSignDate >= this.startDate && this.preSignDate <= tempSigningDate) {
                //    this.loanDetail.preSignDate = moment(this.preSignDate).format();
                //} else {
                //    Swal.fire("Error: Changes not saved!", "Pre-Signing Date must be between the Available for Review Date and the Signing Date", "error");
                //    return false;
                //}
                
                this.loanDetail.preSignDate = moment(this.preSignDate).format();

                this.loanDetail.updatedBy = this.$store.state.Admin.loginInformation.userId;
                this.loanDetail.timeZone = this.selectedTZ.id;
                this.loanDetail.clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

                return ApiService.GeneratePostRequest(
                    "Package/UpdateLoanPackage",
                    this.loanDetail
                ).then((response) => {
                    if (response.isSuccess) {
                        this.signDate = new Date(moment.parseZone(response.content.signingDate).format('YYYY-MM-DDTHH:mm:ss'));
                        Swal.fire("Changes Saved!", "", "success");
                        console.log(this.loanDetail)
                        this.$refs["packageDocGrid_" + this.loanDetail.loanPackageID][0].reloadGrid();
                    } else {
                        Swal.fire("Failed", response.message, "error");
                    }
                });
            },
            loadTimeZones: function () {
                return ApiService.GeneratePostRequest("Package/timezones").then(
                    (response) => {
                        this.timeZones = response.content;

                        //set default
                        //ApiService.GenerateGetRequest(
                        //    "Admin/LenderSettings"
                        //).then((response) => {
                        //    //get timezone details
                        //    return ApiService.GenerateGetRequest("Package/timezone/" + response.content.timeZone).then(
                        //        response => {
                        //            this.selectedTZ = response.content != null ? response.content : this.timeZones.find(x => x.id == 7);

                        //        });
                        //});

                    });
            },
            changeDateTime(event) {
                console.log(event)
                switch(event.target.name){
                    case "signingExpirationDate":
                        this.signingExpirationDate = event.target.value;
                        break;
                    case "signingDate":
                        this.signDate = event.target.value;
                        break;
                    default:
                        break;
                }
            },
            changeTimeZone(event) {
                this.selectedTZ = event.value;
            },
            normalizeStatus(string) {
                return GridDataHelper.normalizeStatus(string);
            },
            groupBy(data, property) {
                return data.reduce((acc, obj) => {
                    const key = obj[property];
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(obj);
                    return acc;
                }, {});
            },
            async handleForceClose() {
                var self = this;
                Swal.fire({
                    title: 'Are you sure you want to force close this package?',
                    icon: 'info',
                    showCancelButton: true,
                }).then(async (result) => {
                    if (!result.isConfirmed) { return; }
                    let response = await ApiService.GeneratePostRequest(
                        "signer/ForceCloseLoanByNotary", {
                            loanPackageID: this.loanDetail.loanPackageID,
                            notarizeId: this.loanDetail.notarizeID,
                        }
                    );
                    console.log("FORCE CLOSE RESPONSE", response);                    
                    if (response.isSuccess) {
                        const signerResponse = await ApiService.GenerateGetRequest('signer/ndetails?NotarizeID=' + this.loanDetail.notarizeID);
                        this.signer = signerResponse["content"];
                        self.signerData = self.$store.getters["Auth/signerSessionInformation"];
                        var payload = {
                            "SignerId": self.signer.id,
                            "SignerName": self.signer["firstName"] + " " + self.signer["lastName"],
                            "SignerEmail": self.signer["emailAddress"],
                            "PackageName": self.signerData["description"],
                            "NotarizeId": self.NotarizeID,
                            "RequesterId": self.$store.state.Admin.loginInformation.userId,
                            "Lender": self.signerData["lender"]
                        };
                        ApiService.GeneratePostRequest(`econsent/signingComplete/sendLenderNotification`, payload);
                        Swal.fire({
                            title: 'Loan Closed Successfully',
                            text: '',
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        });
                    } else {
                        if (response.content == null) {
                            Swal.fire({
                                title: 'Force Close Failed',
                                text: response.message,
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            });
                        } else {
                            this.groupedOutstandingTags = this.groupBy(response.content, "signerName");
                            console.log("GROUPED OUTSTANDING TAGS", this.groupedOutstandingTags);
                            //$(this.$refs.outstandingTagsModal).modal("show");
                        }
                        
                    }
                })
            },
            async handleNotaryJournal() {
                this.$router.push("/admin/notary/" + this.loanDetail.loanPackageID);
            },
            async viewSigningRoom(e) {
                e.preventDefault();
                var userID = this.$store.state.Admin.loginInformation.userId;
                var notarizeID = this.loanDetail.notarizeID;
                this.$store.commit("Auth/updateSigningRoomData",
                    {
                        userId: userID,
                        contextId: 0,
                        notarizeId: notarizeID,
                        hist: "Pipeline"
                    });
                this.$router.push('/admin/signing');
            }
        },
    };
</script>
